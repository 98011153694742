import React from 'react'
import {Container, Row, Col} from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {withPrefix} from 'gatsby';
import {Pricing} from '../components/pricing';
import GlobaleSucheImage from '../components/GlobaleSuche';
import GlobaleSucheFeaturette from '../components/GlobaleSucheFeaturette';

const GlobaleSuche = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - optimiert Ihre Praxis"
             googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
             fbUrl="https://medoboard.com/globale-suche"
             fbImage={withPrefix('medoboard-fb.png')}
             fbTitle="Medoboard - optimiert Ihre Praxis"
             fbDescription="Medoboard unterstützt Sie in ihrer täglichen Arbeit mit dem Patienten damit Sie mehr Zeit für das Wesentliche haben"
             keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]}/>
        <Container>
            <Row className="carousel">
                <Col md="12">
                    <GlobaleSucheImage/>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Finden - nicht suchen. Von überall.</h2>
                    <h4 className="text-muted mb-5">Medoboard ist auf Performance optimiert - und findet alles, schneller als
                        Sie tippen können</h4>
                    <p className="lead">
                        Medoboard indexiert ihre Patientenkartei extrem effizient sodass Sie jederzeit mit einem
                        Tastendruck Ihre
                        gesamte Patientenkartei blitzschnell durchsuchen können.
                    </p>
                    <ul className="lead">
                        <li>Patientensuche direkt im Header ermöglicht jederzeit Zugriff auf die gesamte
                            Patientenakte
                        </li>
                        <li>
                            Blitzschnelle Ergebnisse - auch offline ohne Internetanbindung
                        </li>
                        <li>
                            Schneller als Sie tippen können
                        </li>
                    </ul>
                </Col>
            </Row>

            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
                    <GlobaleSucheFeaturette imageTitle="Globale Suche"/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Pricing/>
        </Container>
    </Layout>
)

export default GlobaleSuche;