import {Col, Row} from "reactstrap";
import React from "react";
import SearchImage from "./SearchImage";

const GlobaleSucheFeaturette = ({imageTitle, imageText}) => (
    <Row className="featurette">
        <Col md="5">
            <SearchImage/>
        </Col>
        <Col md="7" className="px-5">
            <div className="featurette-content">
                <div>
                    <h2 className="my-4">Globale Suche</h2>
                    <p>
                        Medoboard legt höchsten Wert auf einfache Bedienung: Die Suche kann von überall einfach im
                        Header durch eine einfache Eingabe gestartet werden.
                    </p>
                    <p>
                        Durch die Realisierung als "Offline-first" Suche ist die Suche extrem schnell. Tausende Patientenakten
                        sind mit einem Klick verfügbar.
                    </p>
                    <p>
                        Sie (und alle ihre Mitarbeiter) behalten immer den Überblick und können blitzschnell zwischen den
                        Patientenakten wechseln. In Bruchteilen einer Sekunde.
                    </p>
                </div>
            </div>
        </Col>
    </Row>
)

export default GlobaleSucheFeaturette;